<template>
    <div class="scd">
        <div class="header1 page-header">
            <h1 class="title">売上/原価詳細</h1>
            <div style="display: inline-block">
                <OpSelectFiscalyear
                    v-model="fiscalyear"
                    label="会計年度"
                    @input="changedYear"
                    :company="getCompany()"
                    required
                    hideheader
                />
            </div>
            <div style="display: inline-block">
                <OpRadioGroup
                    v-model="state.perioddiv"
                    v-slot="{ item }"
                    :choices="perioddivlist"
                    :itemKey="item => item.key"
                    @input="changedFilter"
                >
                    {{ item.name }}
                </OpRadioGroup>
            </div>
        </div>
        <div class="header2">
            <div class="soposts">
                <!-- 受注ステータス -->
                <OpDivMultiSelector
                    label="受注ステータス"
                    v-model="salesorder_status"
                    :divlist="sostslist"
                    name="sosts"
                    divViewType="status"
                />
            </div>
            <div class="soposts">
                <!-- 発注ステータス -->
                <OpDivMultiSelector
                    label="発注ステータス"
                    v-model="purchaseorder_status"
                    :divlist="postslist"
                    name="posts"
                    divViewType="status"
                />
            </div>
        </div>

        <fieldset class="departments">
            <legend>
                <label>
                    <OpCheckbox
                        v-model="alldept"
                        name="alldept"
                        @change="changeAllDept()"
                    />
                    すべての部署
                </label>
            </legend>
            <div>
                <fieldset v-for="d in depts" :key="d.department_id">
                    <legend>
                        <label>
                            <OpCheckbox
                                v-model="d.checked"
                                name="visible_detail"
                                @change="inputDept(d)"
                            />
                            {{ d.department_name_short }}
                        </label>
                    </legend>
                    <div v-for="dc in d.children" :key="dc.department_id">
                        <label>
                            <OpCheckbox
                                v-model="dc.checked"
                                name="visible_detail"
                                @change="inputDept(dc)"
                            />
                            <span class="cdeptname">{{
                                dc.department_name_short
                            }}</span>
                        </label>
                    </div>
                </fieldset>
            </div>
        </fieldset>

        <div class="downloadblock">
            <button @click="download" :disabled="loading">
                <IconBase
                    iconName="Excel"
                    width="48"
                    height="48"
                    visibleName
                    v-slot="{ viewbox }"
                    :disabled="loading"
                >
                    <IconDownload @viewbox="viewbox" />
                </IconBase>
            </button>
        </div>
    </div>
</template>

<script>
import OpSelectFiscalyear from "@/components/OpSelectFiscalyear.vue";
// import OpSelectDepartment from "@/components/OpSelectDepartment.vue";
import OpDivMultiSelector from "@/components/OpDivMultiSelector.vue";
import OpCheckbox from "@/components/OpCheckbox.vue";
import OpRadioGroup from "@/components/OpRadioGroup.vue";
import IconBase from "@/components/icons/IconBase.vue";
import IconDownload from "@/components/icons/IconDownload.vue";
import DateUtils from "@/DateUtils";

export default {
    name: "SalesCostDetail",
    components: {
        OpSelectFiscalyear,
        // OpSelectDepartment,
        OpDivMultiSelector,
        OpRadioGroup,
        OpCheckbox,
        IconBase,
        IconDownload
    },
    props: {},
    data: function() {
        let comp = this.getCompany();
        let fh = DateUtils.getFiscalYearAndHalfsignToday(comp);
        let dts = DateUtils.getFiscalRangeDate(comp, fh.fiscalyear, 0);
        // let mlist = DateUtils.getYearMonthList(dts.start, dts.end);
        // let today = new Date();
        return {
            fiscalyear: fh.fiscalyear,
            startdate: dts.start,
            enddate: dts.end,
            salesorder_status: ["A", "B", "C", "D"],
            purchaseorder_status: ["A", "B", "C", "D"],
            refreshCount: 1,
            perioddivlist: [
                { key: 0, name: "通期" },
                { key: 1, name: "上期" },
                { key: 2, name: "下期" }
            ],
            sostslist: {},
            postslist: {},
            depts: {},
            deptlist: [],
            alldept: true,
            state: {
                perioddiv: fh.halfsign,
                department: {}
            }
        };
    },
    watch: {
        dpstatus: function() {
            this.refreshCount++;
        }
    },
    methods: {
        formatCaption: function(sts) {
            let ret = "";
            let y = this.fiscalyear.key;
            if (!y) y = this.fiscalyear;
            let ey = this.getCompany().establishment_year;
            ret += DateUtils.formatFiscalYear(y, ey);

            let sss = "";
            for (let item of sts) {
                sss += "," + item;
            }
            if (sss != "") {
                sss = sss.substring(1);
            }
            ret += " " + sss;
            return ret;
        },
        formatlink: function(sts, podiv) {
            let ret = "";
            if (podiv) {
                let div = this.divs["po"][podiv];
                if (div != null) {
                    ret += " ( " + div.contentlong + " ) ";
                }
            }

            let sss = "";
            for (let item of sts) {
                sss += "" + item;
            }

            ret += sss;

            return ret;
        },

        changedFilter: function() {
            let y = null;
            if (this.fiscalyear.key) {
                y = this.fiscalyear;
            } else {
                y = { key: this.fiscalyear };
            }
            this.changedYear(y);
        },
        changedYear: function(year) {
            let dts = DateUtils.getFiscalRangeDate(
                this.getCompany(),
                year.key,
                this.state.perioddiv
            );
            this.startdate = dts.start;
            this.enddate = dts.end;
            // this.loaddata();
            this.loaddepts();
        },
        download: function() {
            // let params = this.makeParams();
            let y = this.fiscalyear.key;
            if (!y) y = this.fiscalyear;
            let filename = "売上原価詳細";
            filename += "-" + y + "年度";
            for (const pdiv of this.perioddivlist) {
                if (pdiv.key == this.state.perioddiv) {
                    filename += pdiv.name;
                    break;
                }
            }
            filename += "_" + this.salesorder_status;
            filename += "-" + this.purchaseorder_status;
            filename = filename.replace(/,/g, "");

            const sd = this.startdate;
            const ed = this.enddate;
            const params = [];
            params["period"] = this.dateTo8Str(sd) + "-" + this.dateTo8Str(ed);

            if (this.salesorder_status && this.salesorder_status.length > 0) {
                params["sosts"] = this.salesorder_status;
            }
            if (
                this.purchaseorder_status &&
                this.purchaseorder_status.length > 0
            ) {
                params["posts"] = this.purchaseorder_status;
            }
            // if (this.state.department && this.state.department.department_id) {
            //     params["departments"] = [this.state.department.department_id];
            // }
            let ddd = [];
            let cnt0 = 0;
            let cnt1 = 0;
            for (let pid in this.depts) {
                let pdept = this.depts[pid];
                for (let dept of pdept.children) {
                    cnt0++;
                    if (dept.checked) {
                        cnt1++;
                        ddd.push(dept.department_id);
                    }
                }
            }
            if (cnt0 != cnt1) {
                params["departments"] = ddd;
            }

            filename += "_" + DateUtils.toString8();
            filename += ".xlsx";

            this.downloadData(
                this.makeurl("salescostdetail", "xlsx", params),
                filename
            );
        },
        filterSopolist: function() {
            let slist = this.divs["sosts"];
            let ss = {};
            for (let v in slist) {
                if (v != "Z") {
                    ss[v] = slist[v];
                }
            }
            this.sostslist = ss;

            let plist = this.divs["posts"];
            let ps = {};
            for (let v in plist) {
                if (v != "Z") {
                    ps[v] = plist[v];
                }
            }
            this.postslist = ps;
        },
        initdeptlist: function(dlist) {
            let clist = [];
            for (let pid in this.depts) {
                let p = this.depts[pid];
                for (let d of p.children) {
                    if (d.checked) {
                        clist.push(d.department_id);
                    }
                }
            }

            let pobj = {};
            for (let dept of dlist) {
                // dept.checked = true;
                const did = dept.department_id;
                dept.checked = clist.includes(did);
                if (dept.department_div == 0) {
                    pobj[did] = dept;
                }
            }

            let tempmap = new Map();
            for (let dept of dlist) {
                const did = dept.department_id;
                if (tempmap.has(did)) {
                    tempmap.get(did).department_name_short +=
                        ", " + dept.department_name_short;
                    continue;
                } else {
                    tempmap.set(did, dept);
                }
                if (dept.department_div == 1) {
                    let p = pobj[dept.upper_department_id];
                    if (p) {
                        if (!p.children) {
                            p.children = [];
                        }
                        p.children.push(dept);
                    }
                }
            }

            return pobj;
        },
        loaddepts: function(func) {
            this.loading = true;
            let params = {
                period: DateUtils.toRequestRangeDate(
                    this.startdate,
                    this.enddate
                )
            };
            return this.readDataToArray(this.makeurl("dept", params), list => {
                this.depts = this.initdeptlist(list);
                this.checkAllChecked();
                console.log(this.depts);
                if (func) {
                    func();
                }
                this.loading = false;
            });
        },
        changeAllDept() {
            const flg = this.alldept;
            for (let pid in this.depts) {
                let p = this.depts[pid];
                p.checked = flg;
                for (let d of p.children) {
                    d.checked = flg;
                }
            }
        },
        inputDept(d) {
            if (d.department_div == 0) {
                console.log(d.children);
                for (let dc of d.children) {
                    dc.checked = d.checked;
                }
            }
            this.checkAllChecked();
        },
        checkAllChecked() {
            let ret = true;
            for (let pid in this.depts) {
                let pret = true;
                let p = this.depts[pid];
                for (let d of p.children) {
                    if (!d.checked) {
                        ret = false;
                        pret = false;
                        break;
                    }
                }
                p.checked = pret;
            }
            this.alldept = ret;
        }
    },
    created: async function() {
        this.loading = true;
        let dd = this.loaddivs("sosts", "posts");
        if (dd instanceof Promise) {
            dd.then(this.filterSopolist);
        } else {
            this.filterSopolist();
        }
        this.changedFilter();
    }
};
</script>

<style scoped>
.header1 {
    height: 80px;
    margin: inherit auto;
}

.header2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    text-align: center;
    vertical-align: top;
    z-index: 0;
    padding: 80px 0 0 0;
    margin: 0 auto;
    max-width: 960px;
}

.soposts {
    padding: 0px 10px;
}

.soposts > div {
    padding: 0px 0px;
    /* transform: scale(0.75); */
}

.page-header {
    position: fixed;
    left: 0px;
    width: calc(100vw - 10px);
}

.departments {
    width: 90%;
    margin: 20px auto;
    position: relative;
}

.departments > div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}

.departments > div > fieldset {
    margin: 10px;
    padding: 10px;
}

.departments > div > fieldset > div {
    margin: 0.5rem 0rem 0.5rem 3rem;
    position: relative;
}

.departments .code {
    font-size: 0.85em;
    color: var(--text-weak-color);
}
.departments .code:after {
    content: " : ";
}
.departments label {
    cursor: pointer;
}

.departments .cdeptname {
    display: inline-block;
    position: absolute;
    padding-top: 0.4rem;
}

.downloadblock {
    text-align: center;
    display: block;
}
.downloadblock button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
